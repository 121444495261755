import React, { useEffect } from 'react';
import conversations from './conversations';

const HomeQuestions = () => {
  const randomIndex = Math.floor(Math.random() * conversations.length);
  const conversation = conversations[randomIndex];
  return (
    <ul className="question-balloons">
      {conversation.map(({ name, time, text }, index) => (
        <li key={time}>
          <div className={`question-balloon ${index % 2 == 0 ? `` : `is-reply`}`}>
            <div className="question-balloon-title has-text-weight-bold mb-2">
              <span className="has-text-primary">{name}</span> om {time}
            </div>
            <p>{text}</p>
          </div>
        </li>
      ))}
    </ul>
  )
}

export default HomeQuestions;