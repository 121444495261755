import React, { useEffect, useRef, useState } from 'react';
// import CloseIcon from '../../assets/svg/close.svg';
// import SearchIcon from '../../assets/svg/search.svg';

const SEARCH_VISIBLE_CLASS = 'search-is-visible';

const Search = ({ formSelector }) => {
    const [searchIsVisible, setSearchIsVisible] = useState();
    const headerElement = document.querySelector('.header');
    const inputRef = useRef();

    const handleKeyDown = (e) => {
        if(e.key === 'Enter') {
           document.querySelector(formSelector).submit();
        }
        if(e.key === 'Escape') {
            setSearchIsVisible(false);
        }
    }

    useEffect(() => {
        if(searchIsVisible) {
            headerElement.classList.add(SEARCH_VISIBLE_CLASS);
            inputRef.current.focus();
        } else {
            //inputRef.current.value = '';
            headerElement.classList.remove(SEARCH_VISIBLE_CLASS);
        }
    }, [searchIsVisible]);

    return (
        <div className="header-search">
            <label htmlFor="search_word" className="is-hidden">Search</label>
            <input ref={inputRef} type="text" name="search_word" className="input header-search-input" onKeyDown={handleKeyDown} onBlur={() => setSearchIsVisible(false)} />
            <input type="hidden" name="type" value="" />
            <button className="header-search-button" onClick={(e) => {
                e.preventDefault();
                setSearchIsVisible(!searchIsVisible)
            }}>
                {!searchIsVisible && (
                    <>
                        <span className="is-sr-only">Search</span>
                        <div className="search-icon"></div>
                    </>
                )}
                {searchIsVisible && (
                    <>
                        <span className="is-sr-only">Close search</span>
                        <div className="close-icon"></div>
                    </>
                )}
            </button>
        </div>
    );
};

export default Search;
