import React, { useState } from 'react';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const middleSegments = [
  { name: "gluon", label: "g", offset: 0, bg: "#db0a5b"},
  { name: "z-boson", label: "Z", offset: 90, bg: "#db0a5b"},
  { name: "w-boson", label: "W", offset: 180, bg: "#db0a5b"},
  { name: "foton", label: "γ", offset: 270, bg: "#db0a5b"},
]

const outerSegments = [
  { name: "down", label: "d", offset: 0, bg: "#db0a5b"},
  { name: "strange", label: "s", offset: 30, bg: "#db0a5b"},
  { name: "bottom", label: "b", offset: 60, bg: "#db0a5b"},
  { name: "tau-neutrino", label: "ν<sub>τ</sub>", offset: 90, bg: "#db0a5b"},
  { name: "muon-neutrino", label: "ν<sub>μ</subt>", offset: 120, bg: "#db0a5b"},
  { name: "elektron-neutrino", label: "ν<sub>e</sub>", offset: 150, bg: "#db0a5b"},
  { name: "elektron", label: "e", offset: 180, bg: "#db0a5b"},
  { name: "muon", label: "μ", offset: 210, bg: "#db0a5b"},
  { name: "tau", label: "τ", offset: 240, bg: "#db0a5b"},
  { name: "top", label: "t", offset: 270, bg: "#db0a5b"},
  { name: "charm", label: "c", offset: 300, bg: "#db0a5b"},
  { name: "up", label: "u", offset: 330, bg: "#db0a5b"},
]

const Segment = ({ segment, value, onClick }) => {
  return <div className={`pie__segment pie__segment__${value}`}
      style={{"--offset": segment.offset, "--bg": segment.bg}}
      onClick={onClick}>
    <div className="pie__segment__inner"></div>  
    <div className="pie__labelbox">
      <div className="pie__labelbox__label">
        <div className={`pie__labelbox__label__symbol pie__labelbox__label__symbol_${segment.label}`}
          dangerouslySetInnerHTML={{ __html: segment.label }}>
        </div>
        <div className={`pie__labelbox__label__name`}>{segment.name}</div>
      </div>
    </div>
  </div>
}

const Poem = ({ title, content }) => {
  return <div className="particle_details__content">
    <h3 className="particle_details__poem_title">{title}</h3>
    <div dangerouslySetInnerHTML={{__html : content}} />
  </div>
}

const PoemAudioFile = ({ mp3Url }) => {
  return <div className="particle_details__content">
    <h3 className="particle_details__poem_title">Luisterfragment</h3>
    <audio controls controlsList="nodownload">
      <source src={mp3Url} type="audio/mpeg" />
      Je browser ondersteund helaas geen audio
    </audio>
  </div>
}

const ParticleDetails = ({ poemParticle, activateParticle, forNnvIframe }) => {
  const [activeType, activateType] = useState('winningPoem');

  const buttonClasses = `button has-text-weight-bold particle_details__button `;

  return <>
    {poemParticle && <div className={`particle_details ${forNnvIframe ? `for_nnv_iframe` : ``}`}>
      <a className={`particle_details__back ${forNnvIframe ? `for_nnv_iframe` : ``}`} 
        onClick={() => {activateParticle(null)}}>Terug</a>

      <h2 className="particle_details__title">{capitalizeFirstLetter(poemParticle.name)}</h2>

      <div className="particle_details__buttons">
        <a className={buttonClasses + (activeType == `winningPoem` ? ` active` : ``)}
          onClick={() => activateType("winningPoem")}>
          Winnend gedicht
        </a>
        <a className={buttonClasses + (activeType == `audio` ? ` active` : ``)}
          onClick={() => activateType("audio")}>
          Luisterfragment
        </a>
        <a className={buttonClasses + (activeType == `hooftPoem` ? ` active` : ``)}
          onClick={() => activateType("hooftPoem")}>
          Gedicht van Gerard 't Hooft
        </a>
      </div>

      {activeType == "winningPoem" && poemParticle.winningPoem && 
        <Poem title="Winnend gedicht" content={poemParticle.winningPoem} />}
      {activeType == "hooftPoem" && poemParticle.hooftPoem && 
        <Poem title="Gedicht Gerard 't Hooft" content={poemParticle.hooftPoem} />}
      {activeType == "audio" && <PoemAudioFile mp3Url={poemParticle.mp3Url} />}
    </div>}
  </>
}


const Poems = ({ particles, forNnvIframe }) => {
  const [activeParticle, activateParticle] = useState();
  const getPoemParticle = (name) => {
    return particles.find((p) => p.name == name);
  }
  const poemParticle = getPoemParticle(activeParticle);

  return <div className="poems__container">
    {!poemParticle && <div className={`pie__container ${poemParticle ? `pie__container_deactivated` : ``}`} 
      onClick={() => activateParticle(null)}>
        <div className="pie_inner" onClick={(e) => {
          e.stopPropagation();
          activateParticle(`higgsdeeltje`)
        }}>
          <div className="symbol__higgs">H</div>
          <div className="label__higgs">higgsdeeltje</div>
        </div>
        <div className="pie pie__middle" style={{"--value": 90}}>
          {middleSegments.map(segment => 
            <Segment key={segment.name} 
              segment={segment} 
              value={90} 
              onClick={(e) => {
                e.stopPropagation();
                activateParticle(segment.name)
              }} />
          )}
        </div>
        <div className="pie pie__outer" style={{"--value": 30}}>
          {outerSegments.map(segment => 
            <Segment key={segment.name} 
              segment={segment}
              value={30}
              onClick={(e) => {
                e.stopPropagation();
                activateParticle(segment.name)
              }} />
          )}
        </div>
    </div>}
    <ParticleDetails poemParticle={poemParticle} 
      activateParticle={activateParticle}
      forNnvIframe={forNnvIframe} />
  </div>
}

export default Poems;