const conversations = [
    [
        {
            name: 'Daan',
            time: '10:37',
            text: 'Ik snap niet hoe het zit met kWh en Joule.',
        },
        {
            name: 'Sandra',
            time: '20:21',
            text: 'Joule en kWh zijn beide eenheden van energie. Watt is het aantal Joule per seconde. Na omrekenen vind je 1 kWh = 3.600.000 J.',
        },
        {
            name: 'Daan',
            time: '21:02',
            text: 'Oh, gewoon omrekenen',
        },
    ],
    [
        {
            name: 'Amina',
            time: '9:23',
            text: 'Ik heb een kijkertje met een vergroting van 1,8 op 2 m. Nu moet ik de vergroting bepalen op 6 m.',
        },
        {
            name: 'Gerard',
            time: '11:04',
            text: 'Bij 2 m lijkt iets 1,8 keer groter, op 6 m ook. Het is gewoon de vergroting.',
        },
        {
            name: 'Amina',
            time: '14:30',
            text: 'Moest ff denken, maar nu snap ik het!',
        },
    ],
    [
        {
            name: 'Tessa',
            time: '10:03',
            text: 'Heeft bètastraling de snelheid van licht?',
        },
        {
            name: 'Jamila',
            time: '11:32',
            text: 'Bètastraling is geen elektromagnetische straling, het is een uitzending van elektronen. Het zijn dus deeltjes, die gaan niet zo snel als het licht.',
        },
        {
            name: 'Tessa',
            time: '19:30',
            text: 'Heel erg bedankt!',
        },
    ],
    [
        {
            name: 'Cédric',
            time: '14:34',
            text: 'Als het volume kleiner wordt, gaat de druk omhoog. Geldt dan ook dat als het volume omhooggaat de druk lager wordt?',
        },
        {
            name: 'Hans',
            time: '15:54',
            text: 'De gaswet zegt pV = constant zolang de temperatuur en de hoeveelheid gas gelijk blijven. Als V 2x groter is, moet p dus 2x kleiner zijn.',
        },
        {
            name: 'Cédric',
            time: '17:06',
            text: 'Dan klopt het dus wat ik al dacht. Bedankt!',
        },
    ],
    [
        {
            name: 'Mare',
            time: '15:59',
            text: 'Ik doe een proefje met een ballon. Nu wil ik berekenen bij welke spanning de ballon knapt. Kunt u mij helpen?',
        },
        {
            name: 'Saïd',
            time: '16:33',
            text: 'Wanneer een ballon knapt, hangt af van het materiaal, de dikte en de trekkracht op de wand. Dit kun je niet zomaar uitrekenen.',
        },
        {
            name: 'Mare',
            time: '19:16',
            text: 'Jammer',
        },
    ],
    [
        {
            name: 'Gijs',
            time: '21:03',
            text: 'Voor mijn huiswerk moet ik berekenen hoelang de batterij van mijn telefoon het doet.',
        },
        {
            name: 'Sanne',
            time: '9:23',
            text: 'Daar zijn geen formules voor, hangt helemaal af van je apps en hoelang je erop zit. Je moet het gewoon meten.',
        },
        {
            name: 'Gijs',
            time: '20:53',
            text: 'Ik zal de leraar vragen of hij dat bedoelt',
        },
    ],
];

export default conversations;
