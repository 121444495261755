import React, { useEffect } from 'react';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import "slick-carousel/slick/slick-theme.css";

const HomeCarousel = ({ article, assignment, movie, applet, newsItem }) => {

  const slider = React.useRef();

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 780,
        settings: "unslick"
      }
    ]
  }

  useEffect(() => {
    setTimeout(() => {
      window.initializeConsent();
      
      // We need to bind jQuery again on consent buttons in the carousel cards
      const buttons = document.querySelectorAll('button[show-cookiebar]');
      buttons.forEach((button) => {
        button.addEventListener('click', () => window.showCookieBar());
      });
     }, 100);

    if(window.initTruncateTitles) {
      setTimeout(() => {
        window.initTruncateTitles();
       }, 0);
    }
  }, [])

  return (
    <>
      <div className="container no-padding-touch">
        <Slider {...settings} ref={slider}>
          {newsItem && <div dangerouslySetInnerHTML={{__html : newsItem}} />}
          {article && <div dangerouslySetInnerHTML={{__html : article}} />}
          {assignment && <div dangerouslySetInnerHTML={{__html : assignment}} />}
          {movie && <div dangerouslySetInnerHTML={{__html : movie}} />}
          {applet && <div dangerouslySetInnerHTML={{__html : applet}} />}
        </Slider>
        <div className="arrows-container">
          <button className="arrow arrow-prev" onClick={() => slider.current.slickPrev()} />
          <button className="arrow arrow-next" onClick={() => slider.current.slickNext()} />
        </div>
      </div>
    </>
  );
}

export default HomeCarousel;