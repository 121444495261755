import React, { useCallback, useEffect, useState } from 'react';
import Select from 'rc-select';
import 'rc-select/assets/index.less';

const Search = ({ groups, currentValue }) => {
    const [selectedOption, setSelectedOption] = useState();
    const [selectWidth, setSelectWidth] = useState();

    const placeholder = "Alle onderwerpen";

    let currentValueName;

    // Get option name for currentValue (prop)
    groups.forEach((group) => {
        group.options.forEach((option) => {
            if (option.id == currentValue) {
                currentValueName = option.name;
            }
        });
    });

    const handleChange = (value, option) => {
        if (option.children) {
            setSelectedOption(option);
        }

        document.querySelector('[name="search[subject_id]"]').value = value;
        document.querySelector('.search-form').submit();
    };

    const calculateSelectWidth = useCallback(() => {
        // Calculate select width based on the length of the current option or the placeholder,
        // by estimated character width (in rem)
        let width = `${(selectedOption && selectedOption.children ? selectedOption.children.length : placeholder.length) * 0.4 + 6}rem`;

        // Responsive width adjustment
        if (window.innerWidth <= 768) {
            width = '100%';
        }

        setSelectWidth(width);
    }, [selectedOption]);

    useEffect(() => {
        calculateSelectWidth();

        window.addEventListener('resize', calculateSelectWidth);

        return () => window.removeEventListener('resize', calculateSelectWidth);
    }, [calculateSelectWidth]);

    useEffect(() => {
        // Set current option based on currentValue prop
        if (currentValue && currentValueName) {
            setSelectedOption({
                key: `${currentValue}`,
                value: currentValue,
                children: currentValueName,
            });
        }
    }, [currentValue, currentValueName]);

    return (
        <Select
            className="search-select select"
            dropdownClassName="select-dropdown"
            style={{ width: selectWidth }}
            placeholder={placeholder}
            defaultValue={currentValueName}
            dropdownMatchSelectWidth={false}
            menuItemSelectedIcon={<></>}
            onChange={handleChange}
        >
            <Select.Option value="">{placeholder}</Select.Option>
            {groups.map((group) => (
                <Select.OptGroup key={group.id} label={group.name}>
                    {group.options.map((option) => (
                        <Select.Option key={option.id} value={option.id}>
                            {option.name}
                        </Select.Option>
                    ))}
                </Select.OptGroup>
            ))}
        </Select>
        
    );
};

export default Search;
